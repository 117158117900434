import { Avatar, Col, Collapse, Row, Typography } from 'antd';
import React from 'react';
import { useGetExchangesQuery } from '../Services/cryptoApi';
import HTMLReactParser from 'html-react-parser';
import millify from 'millify';
import { Preload } from './Preload';

const { Text } = Typography;
const { Panel } = Collapse;
export const Exchanges = () => {
  const { data, isFetching } = useGetExchangesQuery();
  const exchangesList = data?.data?.exchanges;
  if (isFetching) return <Preload />;

  return (
    <>
      <Row>
        <Col span={6}>Exchanges</Col>
        <Col span={6}>24h Trade Volume</Col>
        <Col span={6}>Markets</Col>
        <Col span={6}>Change</Col>
      </Row>
      <Row>
        {exchangesList?.map((exchange) => (
          <Col span={24} key={exchange.uuid}>
            <Collapse>
              <Panel
                key={exchange.uuid}
                showArrow={false}
                header={(
                  <>
                    <Col span={6}>
                      <Text><strong>{exchange.rank}.</strong></Text>
                      <Avatar className="exchange-image" src={exchange.iconUrl} />
                      <Text><strong>{exchange.name}</strong></Text>
                    </Col>
                    <Col span={6}>${millify(exchange["24hVolume"])}</Col>
                    <Col span={6}>{millify(exchange.numberOfMarkets)}</Col>
                    <Col span={6}>{millify(exchange.btcPrice)}%</Col>
                  </>
                )}
              >
                {HTMLReactParser(exchange.coinrankingUrl)}
              </Panel>
            </Collapse>
          </Col>
        ))}
      </Row>
    </>
  );
};
