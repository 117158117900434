import { Col, Typography } from 'antd';
import 'chart.js/auto';
import React from 'react';
import { Line } from 'react-chartjs-2';
const { Title } = Typography;
const LineChart = ({ coinHistory, currentPrice, coinName }) => {
  const coinPrice = [];
  const coinTimestamp = [];

  for (let i = 0; i < coinHistory?.data?.history?.length; i++) {
    coinPrice.push(coinHistory?.data?.history[i].price);
    coinTimestamp.push(new Date(coinHistory?.data?.history[i].timestamp * 1000).toLocaleDateString());
  }

  const data = {
    labels: coinTimestamp.sort(),
    datasets: [
      {
        label: 'Price In USD',
        data: coinPrice,
        fill: false,
        backgroundColor: '#0071bd',
        borderColor: '#0071bd',
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <Col sm={24} xs={24}>
        <div className="chart-header">
          <Title level={3}>{coinName} Price Chart</Title>
          <div className="chart-container">
            <Title level={5} className="chart-change">Change: {coinHistory?.data?.change}%</Title>
            <Title level={5} className="current-price">Current {coinName} Price: ${currentPrice}</Title>
          </div>
        </div>
        <Line data={data} options={options} />
      </Col>
    </>
  );
};

export default LineChart;
