import { configureStore } from '@reduxjs/toolkit'
import { cryptoApi } from '../Services/cryptoApi';
import { cryptoNewsApi } from '../Services/cryptoNewsApi';


export const store = configureStore({
  reducer: {
      [cryptoApi.reducerPath]: cryptoApi.reducer,
      [cryptoNewsApi.reducerPath]: cryptoNewsApi.reducer
  },
});
