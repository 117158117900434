import { Col, Row, Statistic, Typography } from 'antd';
import millify from 'millify';
import React from 'react';
import { Link } from 'react-router-dom';
import { useGetCryptosQuery } from '../Services/cryptoApi';
import { Cryptocurrencies } from './Cryptocurrencies';
import { News } from './News';
import { Preload } from './Preload';
const { Title } = Typography;
export const HomePage = () => {
    const { data, isLoading } = useGetCryptosQuery(10);
    if (isLoading) return <Preload />;
    const globalStats = data?.data?.stats;
    return (
        <>
            <Title level={2} className="heading">
                Global Crypto stats
            </Title>
            <Row>
                <Col span={12}><Statistic title="Total Cryptocurrencies" value={globalStats.total} /></Col>
                <Col span={12}><Statistic title="Total Exchanges" value={millify(globalStats.totalExchanges)} /></Col>
                <Col span={12}><Statistic title="Total Market Cap" value={millify(globalStats.totalMarketCap)} /></Col>
                <Col span={12}><Statistic title="Total 24h Volume" value={millify(globalStats.total24hVolume)} /></Col>
                <Col span={12}><Statistic title="Total Markets" value={millify(globalStats.totalMarkets)} /></Col>
            </Row>
            <div className="home-heading-container">
                <Title level={2} className="home-title">Top 10 Cryptocurrencies in the World</Title>
            </div>
            <Cryptocurrencies simplified={true} />
            <div className="btn-readmore-container">
                <Link to="/cryptocurrencies" className="button" >Read more</Link>
            </div>
            <div className="home-heading-container">
                <Title level={2} className="home-title">Top 10 Crypto News</Title>
            </div>
            <News simplified={true} />
            <div className="btn-readmore-container">
                <Link to="/news" className="button">Read more</Link>
            </div>
        </>
    );
};
