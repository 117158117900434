import { AntDesignOutlined, FormOutlined, HomeOutlined, LineChartOutlined, MenuOutlined, PicRightOutlined } from '@ant-design/icons';
import { Avatar, Button, Menu, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
const Navbar = () => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(undefined);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    useEffect(() => {

        if (window.innerWidth < 992) {
            setScreenSize(window.innerWidth);
        }
        const handleResize = () => setScreenSize(window.innerWidth);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (screenSize <= 992) {
            setActiveMenu(false);
        } else {
            setActiveMenu(true);
        }
    }, [screenSize]);
    const contentMenu = [
        {
            name: 'HomePage',
            path: '/',
            icon: <HomeOutlined />,
            checkActive: ''
        },
        {
            name: 'Exchanges',
            path: '/exchanges',
            icon: <LineChartOutlined />,
            checkActive: 'exchanges'
        },
        {
            name: 'Cryptocurrencies',
            path: '/cryptocurrencies',
            icon: <FormOutlined />,
            checkActive: 'cryptocurrencies'
        }
    ];
    const handleClick = (e) => {
        console.log(e.target);
        if(window.innerWidth <= 992) {
            setActiveMenu(!activeMenu)
        }
    }
    return (
        <div className="nav-container">
            <div className="logo-container">
                <Avatar icon={<AntDesignOutlined style={{ color: '#fff' }} size="large" />} size="large" />
                <Typography.Title level={2} className="logo">
                    <Link to={"/"}>HNA_crypto</Link>
                </Typography.Title> 
            </div>
            <Button className="menu-control-container" onClick={() => setActiveMenu(!activeMenu)}><MenuOutlined /></Button> 
            {activeMenu && (
            <Menu theme="dark" id="menu">
                {contentMenu.map((item, index) => (
                    <Menu.Item icon={item.icon} key={index} className={item.checkActive === splitLocation[1] ? 'active' : '' }>
                        <Link exact to={item.path} onClick={handleClick}>{item.name}</Link>
                    </Menu.Item>
                ))}
            </Menu>)}
        </div >
    );
}

export default Navbar;
