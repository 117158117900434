import { Space, Spin } from 'antd'
import React from 'react'

export const Preload = () => {
    return (
        <div className="preload">
            <Space size="middle">
                <Spin size="large" />
            </Space>
        </div>
    )
}
