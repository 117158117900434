import { Layout, Typography } from 'antd';
import 'antd/dist/antd.min.css';
import React from 'react';
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Cryptocurrencies, CryptoDetails, Exchanges, HomePage, Navbar, News } from './Components';
import { BackGoTop } from './Components/BackGoTop';
import ErrorPage from './Components/ErrorPage';
export default function App() {
    return (<div className="app">
        <div className="navbar"><Navbar /></div>
        <div className="main">
            <Layout>
                <div className="routers">
                    <Routes>
                        <Route exact path="/"
                            element={<HomePage />}
                        />
                        <Route exact path="/exchanges"
                            element={<Exchanges />}
                        />
                        <Route exact path="/cryptocurrencies"
                            element={<Cryptocurrencies />}
                        />
                        <Route exact path="/cryptos/:coinId"
                            element={<CryptoDetails />}
                        />
                        <Route exact path="/news"
                            element={<News />}
                        />
                        <Route exact path="/*"
                            element={<ErrorPage />}
                        />
                    </Routes>
                </div>
            </Layout>
            <div className="footer">
                <Typography.Title level={5}>
                    HNA_crypto <br />
                    All rights reserved.
                </Typography.Title>
            </div>
        </div>
        <BackGoTop />
    </div>);
}