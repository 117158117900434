import { Avatar, Card, Col, Row, Select, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useGetCryptosQuery } from '../Services/cryptoApi';
import { useGetCryptoNewsQuery } from '../Services/cryptoNewsApi';
import { Preload } from './Preload';
const { Title, Text } = Typography;
const { Option } = Select;
const demoImage = "https://picsum.photos/seed/picsum/200/300";
export const News = ({ simplified }) => {
  const [newCategory, setNewCategory] = useState('');
  const { data: cryptoNewsList } = useGetCryptoNewsQuery({ newsCategory: newCategory, count: simplified ? 10 : 100 });
  const { data: cryptoListCategories } = useGetCryptosQuery(100);
  if (!cryptoNewsList?.value) return <Preload />;
  const handleSelectCategory = (value) => {
    setNewCategory(value);
  };
  return (
    <>
      {!simplified && (
        <Row align="middle">
          <Col xs={24} sm={12}>
            <Title className="page-title">NEWS</Title>
          </Col>
          <Col xs={24} sm={12} align="right">
            <Select className="select-news" placeholder="Select a crypto" optionFilterProp="children" filterOption={(input, option) => (option.children.toLowerCase().includes(input.toLowerCase() >= 0))} onChange={handleSelectCategory} filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }>
              {cryptoListCategories?.data?.coins.map((coin, index) => (<Option value={coin.name} key={index}>{coin.name}</Option>))}
            </Select>
          </Col>
        </Row>)}
      {simplified && (
        <Row align="middle" className="select-container">
          <Col md={24} align="right">
            <Select className="select-news" placeholder="Select a crypto" optionFilterProp="children" filterOption={(input, option) => (option.children.toLowerCase().includes(input.toLowerCase() >= 0))} onChange={handleSelectCategory} filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }>
              {cryptoListCategories?.data?.coins.map((coin, index) => (<Option value={coin.name} key={index}>{coin.name}</Option>))}
            </Select>
          </Col>
        </Row>)}
      <Row gutter={[32, 32]}>
        {cryptoNewsList.value.length === 0 && (
          <Col xs={24}>Not found</Col>
        )}
        {cryptoNewsList.value.map((cryptoNew, index) => (
          <Col xs={24} sm={12} md={12} lg={12} xl={8} key={index}>
            <Card hoverable className="news-card">
              <a href={cryptoNew.url} target="_blank" rel="noreferrer">
                <div className="news-image-container">
                  <Title level={4} className="news-title">{cryptoNew.name}</Title>
                  <img src={cryptoNew?.image?.thumbnail?.contentUrl || demoImage} alt="image" />
                </div>
                <p>{cryptoNew.description > 100 ? `${cryptoNew.description.subString(0, 50)}...` : cryptoNew.description}</p>
                <div className="provider-container">
                  <div>
                    <Avatar src={cryptoNew?.provider[0]?.image?.thumbnail?.contentUrl} alt="image" />
                    <Text className="provider-name">{cryptoNew?.provider[0]?.name}</Text>
                  </div>
                  <Text>{moment(cryptoNew.datePublished).startOf('ss').fromNow()}</Text>
                </div>
              </a>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};
