import { CheckOutlined, DollarCircleOutlined, ExclamationCircleOutlined, FundOutlined, MoneyCollectOutlined, NumberOutlined, StopOutlined, ThunderboltOutlined, TrophyOutlined } from '@ant-design/icons';
import { Col, Row, Select, Typography } from 'antd';
import millify from 'millify';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCryptoNewDetailsQuery, useGetCryptoHistoryQuery } from '../Services/cryptoApi';
import HTMLReactParser from 'html-react-parser';
import LineChart from './LineChart';
import { Preload } from './Preload';
const { Title, Text } = Typography;
const { Option } = Select;

export const CryptoDetails = () => {
  const { coinId } = useParams();
  const [timePeriod, setTimePeriod] = useState('7d');
  const { data, isFetching } = useGetCryptoNewDetailsQuery(coinId);
  const { data: coinHistory } = useGetCryptoHistoryQuery({ coinId, timePeriod });
  const cryptoDetails = data?.data?.coin;
  const time = ['3h', '24h', '7d', '30d', '1y', '3m', '3y', '5y'];
  const stats = [
    { title: 'Price to USD', value: `$ ${cryptoDetails?.price && millify(cryptoDetails?.price)}`, icon: <DollarCircleOutlined /> },
    { title: 'Rank', value: cryptoDetails?.rank, icon: <NumberOutlined /> },
    { title: '24h Volume', value: `$ ${cryptoDetails?.["24hVolume"] && millify(cryptoDetails?.["24hVolume"])}`, icon: <ThunderboltOutlined /> },
    { title: 'Market Cap', value: `$ ${cryptoDetails?.marketCap && millify(cryptoDetails?.marketCap)}`, icon: <DollarCircleOutlined /> },
    { title: 'All-time-high(daily avg.)', value: `$ ${cryptoDetails?.allTimeHigh?.price && millify(cryptoDetails?.allTimeHigh?.price)}`, icon: <TrophyOutlined /> },
  ];

  const genericStats = [
    { title: 'Number Of Markets', value: cryptoDetails?.numberOfMarkets, icon: <FundOutlined /> },
    { title: 'Number Of Exchanges', value: cryptoDetails?.numberOfExchanges, icon: <MoneyCollectOutlined /> },
    { title: 'Aprroved Supply', value: cryptoDetails?.supply?.confirmed ? <CheckOutlined /> : <StopOutlined />, icon: <ExclamationCircleOutlined /> },
    { title: 'Total Supply', value: `$ ${cryptoDetails?.supply?.total && millify(cryptoDetails?.supply?.total)}`, icon: <ExclamationCircleOutlined /> },
    { title: 'Circulating Supply', value: `$ ${cryptoDetails?.supply?.circulating && millify(cryptoDetails?.supply?.circulating)}`, icon: <ExclamationCircleOutlined /> },
  ];
  if (isFetching) return <Preload />;

  return (
    <Row className="coin-detail-container" gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12}>
        <Title level={2} className="coin-name">
          {data?.data?.coin.name} ({data?.data?.coin.symbol}) Price
        </Title>
        <p>{cryptoDetails?.name} live price in US Dollar (USD). View value statistics, market cap and supply.</p>
      </Col>
      <Col md={12} xs={24} sm={24} align="right">
        <Select defaultValue="7d"
          className="select-timeperiod"
          placeholder="Select Timeperiod"
          onChange={(value) => setTimePeriod(value)}>
          {time.map((date) => <Option key={date}>{date}</Option>)}
        </Select>
      </Col>
      <LineChart coinHistory={coinHistory} currentPrice={millify(cryptoDetails.price)} coinName={cryptoDetails.name} />
      <Col className="stats-container" md={12} sm={24} xs={24}>
        <div className="coin-value-statistics">
          <Col className="coin-value-statistics-heading">
            <Title level={3} className="coin-details-heading">
              {cryptoDetails?.name} Value statistics
            </Title>
            <p>
              An overview showing the stats of {cryptoDetails?.name}
            </p>
          </Col>
          {stats.map(({ title, value, icon }, index) => (
            <Col className="coin-stats" key={index}>
              <Col className="coin-stats-name">
                <Text>{icon}</Text>
                <Text>{title}</Text>
              </Col>
              <Text className="stats">{value}</Text>
            </Col>
          ))}
        </div>
      </Col>
      <Col className="other-stats-container" md={12} sm={24} xs={24}>
        <Col className="coin-value-statistics">
          <Col className="coin-value-statistics-heading">
            <Title level={3} className="coin-details-heading">
              Other statistics
            </Title>
            <p>
              An overview showing the stats of All cryptocurrencies
            </p>
          </Col>
          {genericStats.map(({ title, value, icon }, index) => (
            <div className="coin-stats" key={index}>
              <div className="coin-stats-name">
                <Text>{icon}</Text>
                <Text>{title}</Text>
              </div>
              <Text className="stats">{value}</Text>
            </div>
          ))}
        </Col>
      </Col>
      <Col className="coin-desc-link" md={12} sm={24} xs={24}>
        <Row className="coin-desc">
          <Title level={3} className="coin-desc-heading">
            What is {cryptoDetails.name}
          </Title>
          {HTMLReactParser(cryptoDetails.description)}
        </Row>
      </Col>
      <Col className="coin-links" md={12} sm={24} xs={24}>
        <Title level={3} className="coin-details-heading">{cryptoDetails.name} Links</Title>
        {cryptoDetails.links?.map((link) => (
          <Row className="coin-link" key={link.name}>
            <Title level={5} className="link-name">{link.type}</Title>
            <a href={link.url} target="_blank" rel="noreferrer">{link.name}</a>
          </Row>
        ))}
      </Col>
    </Row>
  );
};
