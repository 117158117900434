import { Card, Col, Input, Row, Typography } from "antd";
import millify from "millify";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useGetCryptosQuery } from "../Services/cryptoApi";
import { Preload } from "./Preload";
const { Title } = Typography;
export const Cryptocurrencies = ({ simplified }) => {
    const count = simplified ? 10 : 100;
    const { data: cryptoList, isFetching } = useGetCryptosQuery(count);
    const [cryptos, setCryptos] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    useEffect(() => {
        const filterData = cryptoList?.data?.coins.filter((coin) => coin.name.toLowerCase().includes(searchKey.toLowerCase()))
        setCryptos(filterData);
    }, [searchKey, cryptoList]);
    if (isFetching) return <Preload />;
    const handleChange = (e) => {
        setSearchKey(e.target.value);
    }
    return (
        <>
            {!simplified && (
                <Row align="middle">
                    <Col xs={24} md={20}>
                        <Title className="page-title">CRYPTOCURRENCIES</Title>
                    </Col>
                    <Col xs={24} md={4} align="right"><Input placeholder="Search cryptocurrency" onChange={handleChange} className="cryptocurrency-search" /></Col>
                </Row>
            )}
            <Row gutter={[32, 32]} className="crypto-card-container">
                {cryptos?.map(crypto => (
                    <Col xs={24} sm={12} md={8} lg={12} xl={8} xxl={6} className="crypto-card" key={crypto.uuid}>
                        <Link to={`/cryptos/${crypto.uuid}`}>
                            <Card
                                title={`${crypto.rank} . ${crypto.name}`}
                                extra={<img className="crypto-image" src={crypto.iconUrl} alt={crypto.iconUrl} />}
                                hoverable
                            >
                                <p>Price: {millify(crypto.price)}</p>
                                <p>Market Cap: {millify(crypto.marketCap)}</p>
                                <p>Daily Change: {millify(crypto.change)}</p>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </>
    );
};
