import { CaretUpOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'

export const BackGoTop = () => {
    const [position, setPosition] = useState({ top: 0, left: 0 })
    useEffect(() => {
        window.scroll({
            top: position.top,
            left: position.left,
            behavior: 'smooth'
        })
    })
    const scrollTop = useRef()
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                scrollTop.current.style.opacity= 1; 
                scrollTop.current.style.visibility= 'visible';
            } else {
                scrollTop.current.style.opacity= 0; 
                scrollTop.current.style.visibility= 'hidden';
            }
        })
        return () => {
            window.removeEventListener("scroll");
        }
    },[])
    return (
        <div id="btn-goTop" ref={scrollTop} onClick={() => setPosition({ ...position, position: { top: 0, left: 0 } })}><CaretUpOutlined /></div>
    )
}
