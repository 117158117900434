import React from 'react'
import { Avatar, Col, Collapse, Row, Typography } from 'antd';

const { Title, Text } = Typography;
const ErrorPage = () => {
    return (
        <div className="error-page-container">
            <Title level={1}>404</Title>
            <Text>Page Not Found</Text>
        </div>
    )
}

export default ErrorPage